// Dependencies imports
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Local imports
import Logo from "../../assets/images/logo/avairsense.webp";
import { ReactComponent as ShopIcon } from "../../assets/icons/shopicon.svg";
import MenuIcon from "../../assets/icons/menuicon.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/home/rightarrow.svg";
// Style imports
import He from "./Header.module.scss";

const Header = () => {
  const location = useLocation();
  const [showMobileNav, setShowMobileNav] = useState("");
  const [resource, setResources] = useState(false);
  const [mobileResource, setMobileResource] = useState(false);

  const showAndHideNavbar = () => {
    if (showMobileNav) {
      setShowMobileNav(false);
    } else {
      setShowMobileNav(true);
    }
  };

  return (
    <div className={He.maincontainer}>
      <div className={He.topbar}>
        <h1 className={He.deliveringtext}>
          Delivering now across India | Free Shipping
        </h1>
        <h1 className={He.deliveringtexttwo}>
          Delivering now across India | Free Shipping
        </h1>
      </div>
      <div className={He.navholder} onMouseLeave={() => setResources(false)}>
        <div className={He.logoholder}>
          <Link to="https://avairsense.com">
            <img src={Logo} alt="logo" className={He.logo} />
          </Link>
        </div>
        <div className={He.iconsholder}>
          <a href="https://shop.avairsense.com/">
            <ShopIcon className={He.shopicon} />
          </a>
          <img
            src={MenuIcon}
            alt="menuicon"
            className={He.menuicon}
            onClick={() => showAndHideNavbar()}
          />
        </div>

        <div className={He.linksholder}>
          <Link
            to="/"
            className={
              location.pathname === "/" ? He.navlink_active : He.navlink
            }
          >
            <h4>Home</h4>
          </Link>

          {/* <Link
            to="/features"
            className={
              location.pathname === "/features" ? He.navlink_active : He.navlink
            }
          >
            <h4>Features</h4>
          </Link> */}
          <Link
            to="/support"
            className={
              location.pathname === "/support" ? He.navlink_active : He.navlink
            }
          >
            <h4>Support</h4>
          </Link>
          <div
            className={He.resourcespagelinks}
            onMouseEnter={() => setResources(true)}
          >
            <div className={He.resourceiconholder}>
              <p className={He.subnavlink}>Resources</p>
              <RightArrow
                className={resource ? He.rightarrowactive : He.rightarrow}
              />
            </div>
            <div
              className={
                resource ? He.submenulinksholderactive : He.submenulinksholder
              }
            >
              <Link
                to="/howtoresources"
                className={
                  location.pathname === "/howtoresources"
                    ? He.navlink_active
                    : He.navlink
                }
              >
                <h4>How to Videos</h4>
              </Link>
              <Link
                to="/blogs"
                className={
                  location.pathname === "/blogs"
                    ? He.navlink_active
                    : He.navlink
                }
              >
                <h4>Blogs</h4>
              </Link>
            </div>
          </div>
          <a href="https://shop.avairsense.com/" className={He.navlink}>
            <h4>Shop</h4>
          </a>
        </div>
        <div
          className={
            showMobileNav === ""
              ? He.mobilelinksholderinitial
              : showMobileNav
              ? He.mobilelinksholderShown
              : He.mobilelinksholderHidden
          }
          onMouseLeave={() => setResources(false)}
        >
          <Link
            to="/"
            className={
              location.pathname === "/" ? He.navlink_active : He.navlink
            }
          >
            <h4>Home</h4>
          </Link>
          {/* <Link
            to="/features"
            className={
              location.pathname === "/features" ? He.navlink_active : He.navlink
            }
          >
            <h4>Features</h4>
          </Link> */}
          <Link
            to="/support"
            className={
              location.pathname === "/support" ? He.navlink_active : He.navlink
            }
          >
            <h4>Support</h4>
          </Link>
          <div
            className={He.resourcemobilelinks}
            onClick={() => setMobileResource(mobileResource ? false : true)}
          >
            <div className={He.resourceiconholder}>
              <p className={He.subnavlink}>Resources</p>
              <RightArrow
                className={
                  mobileResource
                    ? He.mobilerightarrow
                    : He.mobilerightarrowactive
                }
              />
            </div>
            <div
              style={{
                display: mobileResource ? "flex" : "none",
                marginTop: "4vh",
                transition: "all 0.25s ease-in-out",
              }}
              className={He.mobileresourcelinksholder}
            >
              <Link
                to="/howtoresources"
                className={
                  location.pathname === "/howtoresources"
                    ? He.navlink_active
                    : He.navlink
                }
              >
                <h4>How to Videos</h4>
              </Link>
              <Link
                to="/blogs"
                className={
                  location.pathname === "/blogs"
                    ? He.navlink_active
                    : He.navlink
                }
              >
                <h4>Blogs</h4>
              </Link>
            </div>
          </div>
          <a href="https://shop.avairsense.com/" className={He.navlink}>
            <h4>Shop</h4>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
