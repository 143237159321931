// Dependencies imports
import React from "react";
import { Helmet } from "react-helmet";

// Local imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// Style imports
import Re from "./RefundPolicy.module.scss";

const RefundPolicy = () => {
  const clickedOnMail = () => {
    window.location.href =
      "mailto:support@avairsense.com?subject=Subject&body=message%20goes%20here";
  };

  return (
    <div className={Re.mainholder}>
      <Helmet>
        <title>Return & Refund Policy | AvairSense</title>
        <meta
          name="description"
          content="AvairSense builds AI cameras for every industry. Sense 1 is our top-of-the-line home security camera, featuring a built-in battery, motion detection, two-way audio, and cloud storage. "
        />
      </Helmet>
      <Header />
      <div className={Re.contentholder}>
        <h1 className={Re.mainhead}>Return & Refund Policy</h1>
        <h4 className={Re.secondpara}>Last Update: 17/11/2022</h4>
        <p className={Re.firstpara}>
          All sales are final, refund will not be issued if there is no
          technical error/fault from our end. However, after analysing the
          scenario, we will consider whether or not to refund. Only after
          completing your payment for monthly/yearly subscription, you may
          request for a refund. Post cancellation of the subscription, customers
          can't claim for a refund. If you have any questions regarding our
          refund policy, write to us at{" "}
          <span className={Re.mailto} onClick={() => clickedOnMail()}>
            support@avairsense.com
          </span>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
