import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/garnett/Garnett-Black.woff";
import "./assets/fonts/garnett/Garnett-Bold.woff";
import "./assets/fonts/garnett/Garnett-Light.woff";
import "./assets/fonts/garnett/Garnett-Medium.woff";
import "./assets/fonts/garnett/Garnett-Regular.woff";
import "./assets/fonts/garnett/Garnett-Semibold.woff";
import ThemeContextWrapper from "./theme/ThemeWrapper";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeContextWrapper>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </ThemeContextWrapper>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
