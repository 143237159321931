// Dependencies import
import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";
import { useRecoilState } from "recoil";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";

// Local imports
import { resourceState } from "../../recoil/atoms/resourcestate";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// Style imports
import Ind from "./ResourceBlog.module.scss";

const ResourceBlog = () => {
  const [blogData, setBlogData] = useState([]);

  //eslint-disable-next-line
  const [resource, setResource] = useRecoilState(resourceState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBlogState();
    //eslint-disable-next-line
  }, []);

  const opts = {
    height: isMobile ? "240" : "360",
    width: isMobile ? "380" : "600",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      VideoPlaybackQuality: "1080p",
    },
  };

  const fetchBlogState = () => {
    let newpath = window.location.pathname.split("/");
    let requiredstate = newpath[2].split(":");
    setResource(requiredstate[0]);
    getIndividualResource(requiredstate[0]);
  };

  const getIndividualResource = (val) => {
    setLoading(true);
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_RESOURCE_ENDPOINT}/api/resources/${val}?populate=*`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setBlogData(result.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return (
    <div className={Ind.individualholder}>
      <Helmet>
        <title>
          {blogData.length !== 0
            ? blogData.attributes.MetaTitle
            : "AvairSense Blogs"}
        </title>
        <meta
          name="description"
          content={
            blogData.length !== 0
              ? blogData.attributes.MetaDescription
              : "Read our blogs about Sense 1 Features, Sense 1 Use Cases, Artificial Intelligence, Smart Home, Tech, Home Security and many more Resources."
          }
        />
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <div className={Ind.blogandrecentholders}>
        <div className={Ind.blogcontentholder}>
          {blogData.length !== 0 ? (
            <div className={Ind.blogholder}>
              <h3 className={Ind.BlogCategory}>How to videos</h3>
              <h3 className={Ind.BlogPublishedOn}>
                {moment(blogData.attributes.createdAt).format("MMM Do YY")}
              </h3>
              <h2 className={Ind.BlogHeading}>{blogData.attributes.Heading}</h2>
              <YouTube videoId={blogData.attributes.YoutubeLink} opts={opts} />
              <p className={Ind.SubInfo}>
                {blogData.attributes.ResourceContent}
              </p>
            </div>
          ) : (
            <h2 className={Ind.notfound}>Nothing</h2>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ResourceBlog;
