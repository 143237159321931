import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const offerModalState = atom({
  key: "offerModalState", // unique ID (with respect to other atoms/selectors)
  default: false,
  effects_UNSTABLE: [persistAtom],
  // default value (aka initial value)
});
