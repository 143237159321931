import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const resourceState = atom({
  key: "resourceState", // unique ID (with respect to other atoms/selectors)
  default: "1",
  effects_UNSTABLE: [persistAtom],
  // default value (aka initial value)
});
