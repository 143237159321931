// Dependencies imports
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Lottie from "lottie-react";

// Local imports
import Blogs from "./pages/blogs/Blogs";
import Features from "./pages/features/Features";
import Offers from "./pages/offers/Offers";
import ResourceBlog from "./pages/resources/ResourceBlog";
import TermsAndConditions from "./pages/termsandconditions/TermsAndConditions";
import IndividualBlog from "./pages/blogs/individualPost";
import RefundPolicy from "./pages/refundpolicy/RefundPolicy";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import ShippingAndDelivery from "./pages/shippinganddelivery/ShippingAndDelivery";
import Support from "./pages/support/Support";
import HomeTwo from "./pages/newhome/NewHome.js";
import Resources from "./pages/resources/Resources";
// import ScratchCard from "./pages/offers/ScratchCard";

import ScrollToTop from "./global/ScrollRestoration";
import NotFound404 from "./pages/notfound404/NotFound404";

import SplashAnimation from "./assets/lotties/loader.json";

// Style imports
import AppStyles from "./App.module.scss";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous loading process
    setTimeout(() => {
      setIsLoading(false);
    }, 1600); // Replace this with your actual asset loading logic
  }, []);

  return (
    <div className={AppStyles.mainholder}>
      {isLoading ? (
        <div className={AppStyles.preloaderholder}>
          <Lottie
            animationData={SplashAnimation}
            className={AppStyles.lottie}
            loop={true}
          />
        </div>
      ) : (
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Resources />} />
            <Route exact path="*" element={<NotFound404 />} />
            {/* <Route exact path="/features" element={<Features />} /> */}
            <Route exact path="/blogs" element={<Blogs />} />
            {/* <Route exact path="/offers" element={<Offers />} /> */}
            {/* <Route exact path="/couponcode" element={<ScratchCard />} /> */}
            <Route exact path="/blog/:slug" element={<IndividualBlog />} />
            <Route exact path="/howtoresources" element={<Resources />} />
            <Route exact path="/resource/:slug" element={<ResourceBlog />} />
            <Route
              exact
              path="/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route exact path="/refundpolicy" element={<RefundPolicy />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/shippinganddelivery"
              element={<ShippingAndDelivery />}
            />
            <Route exact path="/support" element={<Support />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
