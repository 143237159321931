// Dependencies import
import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useRecoilState } from "recoil";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Local imports
import { blogState } from "../../recoil/atoms/blogstate";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// Style imports
import Ind from "./individualPost.module.scss";

const IndividualBlog = () => {
  const [blogData, setBlogData] = useState([]);

  //eslint-disable-next-line
  const [blog, setBlog] = useRecoilState(blogState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBlogState();
    //eslint-disable-next-line
  }, []);

  const fetchBlogState = () => {
    let newpath = window.location.pathname.split("/");
    let requiredstate = newpath[2].split(":");
    setBlog(requiredstate[0]);
    getIndividualBlog(requiredstate[0]);
  };

  const getIndividualBlog = (val) => {
    setLoading(true);
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_STRAPI_ENDPOINT}/api/blogs/${val}?populate=*`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setBlogData(result.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return (
    <div className={Ind.individualholder}>
      <Helmet>
        <title>
          {blogData.length !== 0
            ? blogData.attributes.BlogHeading
            : "AvairSense Blogs"}
        </title>
        <meta
          name="description"
          content={
            blogData.length !== 0
              ? blogData.attributes.SubHeadingOne
              : "Read our blogs about Sense 1 Features, Sense 1 Use Cases, Artificial Intelligence, Smart Home, Tech, Home Security and many more Resources."
          }
        />
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <div className={Ind.blogandrecentholders}>
        <div className={Ind.blogcontentholder}>
          {blogData.length !== 0 ? (
            <div className={Ind.blogholder}>
              <h3 className={Ind.BlogCategory}>
                {blogData.attributes.categories.data[0].attributes.CategoryName}
              </h3>
              <h3 className={Ind.BlogPublishedOn}>
                {moment(blogData.attributes.createdAt).format("MMM Do YY")}
              </h3>
              <h2 className={Ind.BlogHeading}>
                {blogData.attributes.BlogHeading}
              </h2>
              <img
                className={Ind.BlogImage}
                src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.BlogImage.data.attributes.url}`}
                alt={blogData.BlogHeading}
              />
              <p className={Ind.BlogInfo}>{blogData.attributes.BlogInfo}</p>
              <h3 className={Ind.SubHeading}>
                {blogData.attributes.SubHeadingOne}
              </h3>
              <img
                className={Ind.SubImage}
                src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageOne.data[0].attributes.url}`}
                alt={blogData.attributes.SubImageOne.data[0].attributes.name}
              />
              <p className={Ind.SubInfo}>{blogData.attributes.SubInfoOne}</p>
              <h3 className={Ind.SubHeading}>
                {blogData.attributes.SubHeadingTwo}
              </h3>
              {blogData.attributes.SubImageTwo.data === null ? null : (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageTwo.data[0].attributes.url}`}
                  alt={blogData.attributes.SubImageTwo.data[0].attributes.name}
                />
              )}
              {blogData.attributes.SubInfoTwo !== null ? (
                <p className={Ind.SubInfo}>{blogData.attributes.SubInfoTwo}</p>
              ) : null}
              {blogData.attributes.SubHeadingThree !== null ? (
                <h3 className={Ind.SubHeading}>
                  {blogData.attributes.SubHeadingThree}
                </h3>
              ) : null}
              {blogData.attributes.SubImageThree.data !== null ? (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageThree.data[0].attributes.url}`}
                  alt={
                    blogData.attributes.SubImageThree.data[0].attributes.name
                  }
                />
              ) : null}
              {blogData.attributes.SubInfoThree !== null ? (
                <p className={Ind.SubInfo}>
                  {blogData.attributes.SubInfoThree}
                </p>
              ) : null}
              {
                <h3 className={Ind.SubHeading}>
                  {blogData.attributes.SubHeadingFour}
                </h3>
              }
              {blogData.attributes.SubImageFour.data !== null ? (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageFour.data[0].attributes.url}`}
                  alt={blogData.attributes.SubImageFour.data[0].attributes.name}
                />
              ) : null}
              {<p className={Ind.SubInfo}>{blogData.attributes.SubInfoFour}</p>}
              {
                <h3 className={Ind.SubHeading}>
                  {blogData.attributes.SubHeadingFive}
                </h3>
              }
              {blogData.attributes.SubImageFive.data !== null ? (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageFive.data[0].attributes.url}`}
                  alt={blogData.attributes.SubImageFive.data[0].attributes.name}
                />
              ) : null}
              {blogData.attributes.SubInfoFive !== null ? (
                <p className={Ind.SubInfo}>{blogData.attributes.SubInfoFive}</p>
              ) : null}
              {blogData.attributes.SubHeadingSix !== null ? (
                <h3 className={Ind.SubHeading}>
                  {blogData.attributes.SubHeadingSix}
                </h3>
              ) : null}
              {blogData.attributes.SubImageSix.data !== null ? (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageSix.data[0].attributes.url}`}
                  alt={blogData.attributes.SubImageSix.data[0].attributes.name}
                />
              ) : null}
              {<p className={Ind.SubInfo}>{blogData.attributes.SubInfoSix}</p>}
              {
                <h3 className={Ind.SubHeading}>
                  {blogData.attributes.SubHeadingSeven}
                </h3>
              }
              {blogData.attributes.SubImageSeven.data !== null ? (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageSeven.data[0].attributes.url}`}
                  alt={
                    blogData.attributes.SubImageSeven.data[0].attributes.name
                  }
                />
              ) : null}
              {
                <p className={Ind.SubInfo}>
                  {blogData.attributes.SubInfoSeven}
                </p>
              }
              {
                <h3 className={Ind.SubHeading}>
                  {blogData.attributes.SubHeadingEight}
                </h3>
              }
              {blogData.attributes.SubImageEight.data !== null ? (
                <img
                  className={Ind.SubImage}
                  src={`https://beavair-strapi-web.azurewebsites.net/${blogData.attributes.SubImageEight.data[0].attributes.url}`}
                  alt={
                    blogData.attributes.SubImageEight.data[0].attributes.name
                  }
                />
              ) : null}
              {
                <p className={Ind.SubInfo}>
                  {blogData.attributes.SubInfoEight}
                </p>
              }
            </div>
          ) : (
            <h2 className={Ind.notfound}>Nothing</h2>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default IndividualBlog;
