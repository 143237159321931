// Dependencies imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Local imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GradientImage from "../../assets/images/home/background-gradient.webp";

// Style imports
import Not from "./NotFound404.module.scss";

const NotFound404 = () => {
  const [timer, setTimer] = useState(5);
  const navigate = useNavigate();

  const interval = () => setInterval(() => decrementCounter(), 1000);

  const decrementCounter = () => {
    setTimer((prev) => prev - 1);
  };

  useEffect(() => {
    interval();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (timer === 0) {
      navigate("/");
      clearInterval(interval);
    }
    //eslint-disable-next-line
  }, [timer]);

  return (
    <div className={Not.mainholder}>
      <Header />
      <div className={Not.maincontainer}>
        <h1 className={Not.notallowedtext}>⛔️</h1>
        <h2 className={Not.textnotallowed}>404 Page Not Found</h2>
        <h3 className={Not.redirectingtext}>
          Sorry, the page you are looking for, does not exist.
          <br />
          <span className={Not.redirect}>
            You will be redirected in <b>{timer}</b> seconds
          </span>
        </h3>
        <img
          className={Not.backgroundimage}
          alt="backgroundimage"
          src={GradientImage}
        />
      </div>
      <Footer />
    </div>
  );
};

export default NotFound404;
