// Dependencies imports
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Local imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { blogState } from "../../recoil/atoms/blogstate";
import BlogCover from "../../assets/images/blogs/blogscover.webp";
import BlogMobileCover from "../../assets/images/blogs/blogsmobilebanner.webp";

// Style imports
import Bl from "./Blogs.module.scss";

const Blogs = () => {
  const [blogData, setBlogData] = useState([]);
  const [category, setCategory] = useState(0);
  //eslint-disable-next-line
  const [blog, setBlog] = useRecoilState(blogState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllBlogs(category);

    //eslint-disable-next-line
  }, []);

  const getAllBlogs = (val) => {
    setLoading(true);
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_STRAPI_ENDPOINT}/api/blogs?populate=*`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        let temp = [];
        if (val === 0) {
          setBlogData(result.data["data"]);
        }
        if (val === 1) {
          for (let i = 0; i < result.data.data.length; i++) {
            if (
              result.data["data"][i].attributes.categories["data"][0].attributes
                .CategoryName === "Artificial Intelligence"
            ) {
              temp.push(result.data.data[i]);
            }
          }
          setBlogData(temp);
        }
        if (val === 2) {
          for (let i = 0; i < result.data.data.length; i++) {
            if (
              result.data.data[i].attributes.categories.data[0].attributes
                .CategoryName === "Smart Home"
            ) {
              temp.push(result.data.data[i]);
            }
          }
          setBlogData(temp);
        }
        if (val === 3) {
          for (let i = 0; i < result.data.data.length; i++) {
            if (
              result.data.data[i].attributes.categories.data[0].attributes
                .CategoryName === "Tech"
            ) {
              temp.push(result.data.data[i]);
            }
          }
          setBlogData(temp);
        }
        if (val === 4) {
          for (let i = 0; i < result.data.data.length; i++) {
            if (
              result.data.data[i].attributes.categories.data[0].attributes
                .CategoryName === "Home Security"
            ) {
              temp.push(result.data.data[i]);
            }
          }
          setBlogData(temp);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const changeCategory = (val) => {
    setBlogData([]);
    if (val === 0) {
      setCategory(val);
      getAllBlogs(0);
    }
    if (val === 1) {
      setCategory(val);
      getAllBlogs(1);
    }
    if (val === 2) {
      setCategory(val);
      getAllBlogs(2);
    }
    if (val === 3) {
      setCategory(val);
      getAllBlogs(3);
    }
    if (val === 4) {
      setCategory(val);
      getAllBlogs(4);
    }
  };

  return (
    <div className={Bl.mainholder}>
      <Helmet>
        <title>AvairSense Blogs</title>
        <meta
          name="description"
          content="Read our blogs about Sense 1 Features, Sense 1 Use Cases, Artificial Intelligence, Smart Home, Tech, Home Security and many more Resources."
        />
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <img className={Bl.blogcover} src={BlogCover} alt="blogcoverimage" />
      <img
        className={Bl.blogmobilecover}
        src={BlogMobileCover}
        alt="blogmobilecover"
      />
      <div className={Bl.mainblogcontainer}>
        <div className={Bl.linkholders}>
          <h3
            className={category === 0 ? Bl.linktocatactive : Bl.linktocat}
            onClick={() => changeCategory(0)}
          >
            All
          </h3>
          <h3
            className={category === 1 ? Bl.linktocatactive : Bl.linktocat}
            onClick={() => changeCategory(1)}
          >
            Artificial Intelligence
          </h3>
          <h3
            className={category === 2 ? Bl.linktocatactive : Bl.linktocat}
            onClick={() => changeCategory(2)}
          >
            Smart Home
          </h3>
          <h3
            className={category === 3 ? Bl.linktocatactive : Bl.linktocat}
            onClick={() => changeCategory(3)}
          >
            Tech
          </h3>
          <h3
            className={category === 4 ? Bl.linktocatactive : Bl.linktocat}
            onClick={() => changeCategory(4)}
          >
            Home Security
          </h3>
        </div>
        <div className={Bl.blogholder}>
          {blogData.length !== 0 ? (
            blogData.map((value, index) => {
              return (
                <Link
                  to={
                    "/blog/" +
                    value.id +
                    ":" +
                    value.attributes.BlogHeading.replace(/\s+/g, "-")
                  }
                  key={value.id}
                  className={Bl.linktag}
                  onClick={() => {
                    setBlog(value.id.toString());
                  }}
                >
                  <div className={Bl.individualblog}>
                    <div className={Bl.featureimageholder}>
                      <img
                        className={Bl.blogfeatureimage}
                        src={`https://beavair-strapi-web.azurewebsites.net${value.attributes.BlogImage.data.attributes.url}`}
                        alt=""
                      />

                      <h3
                        className={Bl.blogcategory}
                      >{`${value.attributes.categories.data[0].attributes.CategoryName}`}</h3>
                    </div>

                    <h4 className={Bl.blogheading}>
                      {`${value.attributes.BlogHeading.slice(0, 50)}...`}
                    </h4>
                    <p className={Bl.bloginfo}>
                      {`${value.attributes.BlogInfo.slice(0, 100)}...`}
                    </p>
                  </div>
                </Link>
              );
            })
          ) : (
            <h4 className={Bl.nothingtoshow}>Nothing to Show</h4>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
