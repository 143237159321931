// Dependencies imports
import React from "react";
import { Helmet } from "react-helmet";

// Local imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// Style imports
import Sh from "./ShippingAndDelivery.module.scss";

const ShippingAndDelivery = () => {
  const clickedOnMail = () => {
    window.location.href =
      "mailto:support@avairsense.com?subject=Subject&body=message%20goes%20here";
  };

  return (
    <div className={Sh.mainholder}>
      <Helmet>
        <title>Shipping and Delivery | AvairSense</title>
        <meta
          name="description"
          content="AvairSense builds AI cameras for every industry. Sense 1 is our top-of-the-line home security camera, featuring a built-in battery, motion detection, two-way audio, and cloud storage. "
        />
      </Helmet>
      <Header />
      <div className={Sh.contentholder}>
        <h1 className={Sh.mainhead}>Shipping & Delivery</h1>
        <h4 className={Sh.secondpara}>Last Update: 21/11/2022</h4>
        <p className={Sh.firstpara}>
          For International buyers, orders are shipped and delivered through
          registered international courier companies and/or International speed
          post only. For domestic buyers, orders are shipped through registered
          domestic courier companies and /or speed post only. Orders are shipped
          within 6-8 days or as per the delivery date agreed at the time of
          order confirmation and delivering of the shipment subject to Courier
          Company / post office norms. OUREYE PRIVATE LIMITED is not liable for
          any delay in delivery by the courier company / postal authorities and
          only guarantees to hand over the consignment to the courier company or
          postal authorities within 6-8 days from the date of the order and
          payment or as per the delivery date agreed at the time of order
          confirmation. Delivery of all orders will be to the address provided
          by the buyer. Delivery of our services will be confirmed on your mail
          ID as specified during registration. For any issues in utilizing our
          services you may contact our helpdesk on or
          <span className={Sh.mailto} onClick={() => clickedOnMail()}>
            support@avairsense.com
          </span>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingAndDelivery;
