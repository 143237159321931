// Dependencies imports
import React, { useState } from "react";
import { Helmet } from "react-helmet";

// Local imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// Style imports
import Su from "./Support.module.scss";

const Support = () => {
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [contact, setContact] = useState("");
  const [contactErr, setContactErr] = useState("");
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [enableButton, setEnableButton] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const submiturl =
    "https://avairsense.us21.list-manage.com/subscribe/post?u=f9b705ecd04d0d9786d2e8604&amp;id=af27f2eac9&amp;f_id=00c0d8e1f0";

  const listenToErrors = () => {
    if (
      isFormValid === true &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      contact !== "" &&
      message !== ""
    ) {
      setEnableButton(true);
    }
  };

  return (
    <div className={Su.mainholder}>
      <Helmet>
        <title>Customer Support | AvairSense</title>
        <meta
          name="description"
          content="Contact us for all your questions or concerns you may have about your AvairSense camera. Our team of experts is available to assist you in the best possible way. Trust AvairSense for the best in customer support."
        />
      </Helmet>
      <Header />
      <div className={Su.maincontentholder}>
        <div className={Su.lefttextholder}>
          <h3 className={Su.firstwhite}>Sense 1</h3>
          <h3 className={Su.secondwhite}>Support</h3>
        </div>
        <div className={Su.formholder}>
          <div className={Su.formcontainer}>
            <form
              action={submiturl}
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
              className={Su.formitself}
            >
              <input
                type="text"
                className={Su.inputfieldholder}
                placeholder="First Name"
                name="FNAME"
                value={firstName}
                onChange={(e) => {
                  setFirstNameErr("");
                  setFirstName(e.target.value);
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value.trim() === ""
                  ) {
                    setFirstNameErr("First Name cannot be empty");
                    setEnableButton(false);
                    return;
                  }
                  setIsFormValid(true);
                  listenToErrors();
                }}
              />
              <h3 className={Su.error}>{firstNameErr}</h3>
              <input
                type="text"
                className={Su.inputfieldholder}
                placeholder="Last Name"
                name="LNAME"
                value={lastName}
                onChange={(e) => {
                  setLastNameErr("");
                  setLastName(e.target.value);
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value.trim() === ""
                  ) {
                    setLastNameErr("Last Name cannot be empty");
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  setIsFormValid(true);
                  listenToErrors();
                }}
              />
              <h3 className={Su.error}>{lastNameErr}</h3>
              <input
                type="text"
                className={Su.inputfieldholder}
                placeholder="Email"
                value={email}
                name="EMAIL"
                onChange={(e) => {
                  setEmailErr("");
                  setEmail(e.target.value);
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value.trim() === ""
                  ) {
                    setEmailErr("Email cannot be empty");
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  if (
                    !e.target.value.match(
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                    )
                  ) {
                    setEmailErr("Incorrect email format");
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  setIsFormValid(true);
                  listenToErrors();
                }}
              />
              <h3 className={Su.error}>{emailErr}</h3>
              <input
                type="contact"
                className={Su.inputfieldholder}
                placeholder="Contact"
                name="PHONE"
                value={contact}
                onChange={(e) => {
                  setContactErr("");
                  setContact(e.target.value);
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value.trim() === ""
                  ) {
                    setContactErr("Phone number cannot be empty");
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  if (e.target.value.length !== 10) {
                    setContactErr("Phone number should contain 10 digits");
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  if (/^\d+$/.test(e.target.value) === false) {
                    setContactErr("Phone number should contain only numbers");
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  setIsFormValid(true);
                  listenToErrors();
                }}
              />
              <h3 className={Su.error}>{contactErr}</h3>
              <textarea
                rows="4"
                placeholder="How can we help you?"
                className={Su.textareafield}
                name="QUERY"
                value={message}
                onChange={(e) => {
                  setMessageErr("");
                  setMessage(e.target.value);
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value.trim() === ""
                  ) {
                    setMessageErr(
                      "Please enter the message the you want to convey!"
                    );
                    setEnableButton(false);
                    setIsFormValid(false);
                    return;
                  }
                  setIsFormValid(true);
                  listenToErrors();
                }}
              />
              <h3 className={Su.error}>{messageErr}</h3>
              <button
                className={
                  enableButton ? Su.submitticket : Su.submitticketdisabled
                }
                type="submit"
              >
                Submit Ticket
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;
